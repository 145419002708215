// @flow
import Color from 'color'

const PRIMARY = Color.rgb(33, 150, 243)
const SECONDARY = Color.rgb(245, 124, 0)
const SURFACE = Color.rgb(255, 255, 255)
const ON_SURFACE = Color.rgb(0, 0, 0)

const CARD_CONTAINER_SIZE = 400

const defaultTheme = {
  cardColumn: {
    width: 400
  },
  contextBar: {
    height: 48,
    width: 400,
  },
  indicatorList: {
    "title.cell.width": 220,
    "title.typography": 'body2',
    "indicator.typography": 'headline4',
    "indicator.cell.height": 120,
    "indicator.cell.width": 160
  },
  incidentListItem: {
    idColumnWidth: 220,
    idTypography: 'body2',
  },
  investigationListItem: {
    idColumnWidth: 180,
    idTypography: 'body2',
  },
  investigationCardSize:{
    width: CARD_CONTAINER_SIZE - 16
  },
  button: {
    height: 36,
    horizontalPadding: 8,
    containedHorizontalPadding: 16,
    denseButtonHeight: 32,
    disabledInkColor: [0, 0, 0, .38],
    outlineWidth: 2,
  },
  textField: {
    width: 280
  },
  card: {
    cornerRadius: 2,
  },
  iconButton: {
    disabledInkColor: [0, 0, 0, .38],
    size: 24,
  },
  list: {
    dividerColorOnLightBg: [0, 0, 0, .12],
    dividerColorOnDarkBg: [255, 255, 255, .2],
    sidePadding: 16,
    textOffset: 72,
  },
  color: {
    primary: [33, 150, 243],
    _primary: PRIMARY, // FIXME: temp value for when all colors are migrated
    onPrimary: [255, 255, 255],

    secondary: [245, 124, 0],
    _secondary: SECONDARY, // FIXME: temp value for when all colors are migrated
    onSecondary: [255, 255, 255],

    surface: [255, 255, 255],
    _surface: SURFACE,  // FIXME: temp value for when all colors are migrated
    onSurface: [0, 0, 0], // assuming surface is light
    _onSurface: ON_SURFACE,

    onSnackbar: Color.rgb(255, 255, 255).alpha(0.87),

    background: [255, 255, 255],
    backgroundGray: Color.rgb(245, 245, 245),

    textDisabledOnLight: [255, 255, 255, .5],
    white: [255, 255, 255],
    black: [0, 0, 0],

    highEmphasis: {
      onSurface: ON_SURFACE.alpha(0.87),
      onError: Color.rgb(255, 255, 255).alpha(1),
    },

    mediumEmphasis: {
      onSurface: ON_SURFACE.alpha(.6),
    },

    disabledEmphasis: {
      onSurface: ON_SURFACE.alpha(.38),
    },

    error: {
      onSurface: Color.rgb(187, 0, 2).alpha(1),
    },

    divider: {
      onSurface: ON_SURFACE.alpha(0.12),
    },

    ripple: {
      hover: PRIMARY.alpha(.04),
      focus: PRIMARY.alpha(.12),
      active: PRIMARY.alpha(.16),
    }
  },
  ripple: {
    fadeInDuration: 75,
    fadeOutDuration: 150,
    translateDuration: 225,
    statesWashDuration: 15,
    backgroundColor: {
      onDarkInk: 'rgb(0,0,0)',
      onLightInk: 'rgb(255,255,255)',
    },
    opacity: {
      dark: {
        hover: .04,
        focus: .12,
        press: .16,
        selected: .14,
        activated: .12,
      },
      light: {
        hover: .08,
        focus: .22,
        press: .32,
        selected: .16,
        activated: .24,
      },
      onDarkInk: { //TODO: - refactor out
        hover: .04,
        focus: .12,
        press: .16,
        selected: .14,
        activated: .12,
      },
      onLightInk: { //TODO: - refactor out
        hover: .08,
        focus: .22,
        press: .32,
        selected: .16,
        activated: .24,
      },
    },
  },
  palette: {
    primary: {
      background: '#2196F3',
      text: '#FFF'
    },
    secondary: {
      background: '#f57c00',
      text: '#000',
    },
  },
  typography: {
    headline1: {
      fontFamily: 'Roboto',
      fontSize: 96 / 16 + 'rem',
      fontWeight: '300',
      letterSpacing: -1.5 / 16 + 'rem',
      lineHeight: 96 / 16 + 'rem',
    },
    headline2: {
      fontFamily: 'Roboto',
      fontSize: 60 / 16 + 'rem',
      fontWeight: '300',
      letterSpacing: -.5 / 16 + 'rem',
      lineHeight: 60 / 16 + 'rem',
    },
    headline3: {
      fontFamily: 'Roboto',
      fontSize: 48 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: 0 / 16 + 'rem',
      lineHeight: 50 / 16 + 'rem',
    },
    headline4: {
      fontFamily: 'Roboto',
      fontSize: 34 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: .25 / 16 + 'rem',
      lineHeight: 40 / 16 + 'rem',
    },
    headline5: {
      fontFamily: 'Roboto',
      fontSize: 24 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: 0 / 16 + 'rem',
      lineHeight: 32 / 16 + 'rem',
    },
    headline6: {
      fontFamily: 'Roboto',
      fontSize: 20 / 16 + 'rem',
      fontWeight: '500',
      letterSpacing: .25 / 16 + 'rem',
      lineHeight: 32 / 16 + 'rem',
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 16 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: .15 / 16 + 'rem',
      lineHeight: 28 / 16 + 'rem',
    },
    subtitle2: {
      fontFamily: 'Roboto',
      fontSize: 14 / 16 + 'rem',
      fontWeight: '500',
      letterSpacing: .1 / 16 + 'rem',
      lineHeight: 22 / 16 + 'rem',
    },
    body1: {
      fontFamily: 'Roboto',
      fontSize: 16 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: .5 / 16 + 'rem',
      lineHeight: 24 / 16 + 'rem',
    },
    body2: {
      fontFamily: 'Roboto',
      fontSize: 14 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: .25 / 16 + 'rem',
      lineHeight: 20 / 16 + 'rem',
    },
    button: {
      fontFamily: 'Roboto',
      fontSize: 14 / 16 + 'rem',
      fontWeight: '500',
      letterSpacing: .75 / 16 + 'rem',
      lineHeight: '2.25rem',
      textTransform: 'uppercase',
    },
    caption: {
      fontFamily: 'Roboto',
      fontSize: 12 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: .4 / 16 + 'rem',
      lineHeight: 20 / 16 + 'rem',
      marginBottom: 1 + 'rem',
    },
    overline: {
      fontFamily: 'Roboto',
      fontSize: 10 / 16 + 'rem',
      fontWeight: '400',
      letterSpacing: 2 / 16 + 'rem',
      lineHeight: 2 + 'rem',
      textTransform: 'uppercase',
    },
  },
  appbar: {
    height: 56,
    lineHeight: '56px',
    zIndex: 4,
  },
  dialog: {
    zIndex: 24
  },
  drawer: {
    width: 240,
  },
  icon: {
    size: {
      default: 24,
      dense: 20,
    },
    color: {
      light: {
        active: 'rgba(255,255,255,.87)',
        inactive: 'rgba(255,255,255,.54)',
        disabled: 'rgba(255,255,255,.38)',
      },
      dark: {
        active: 'rgba(0,0,0,.87)',
        inactive: 'rgba(0,0,0,.54)',
        disabled: 'rgba(0,0,0,.38)',
      },
    }
  },
  opacity: {
    onDarkInk: {
      hover: .04,
      focus: .12,
      press: .16,
      selected: .14,
      activated: .12,
    },
    onLightInk: {
      hover: .08,
      focus: .22,
      press: .32,
      selected: .16,
      activated: .24,
    }
  },
  sidePanel: {
    width: 'max-content',
    minWidth: CARD_CONTAINER_SIZE
  },
  menu: {
    zIndex: 24
  }
}

export type Theme = typeof defaultTheme

export default defaultTheme
