import React from "react"
import injectStyles from "react-jss"
import classNames from "classnames"
import PropTypes from "prop-types"
import { mixThemedStyles } from "@cybernetex/jss-mixins"
import { mdcTypography } from "./mixins"

const styles = theme => ({
  body1: mixThemedStyles(theme)(
    mdcTypography("body1"),
    { display: "block" },
    // mdcTypographyBaseline('body1', '1.25rem')
  ),
  body2: mixThemedStyles(theme)(
    mdcTypography("body2"),
    { display: "block" },
    // lineHeight(0.875, 1.25)
  ),
  caption: mixThemedStyles(theme)(
    mdcTypography("caption"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  headline1: mixThemedStyles(theme)(
    mdcTypography("headline1"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  headline2: mixThemedStyles(theme)(
    mdcTypography("headline2"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  headline3: mixThemedStyles(theme)(
    mdcTypography("headline3"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  headline4: mixThemedStyles(theme)(
    mdcTypography("headline4"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  headline5: mixThemedStyles(theme)(
    mdcTypography("headline5"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  headline6: mixThemedStyles(theme)(
    mdcTypography("headline6"),
    { display: "block" },
    // mdcTypographyBaseline('caption', '1.25rem')
  ),
  emphasis: props => {
    switch (props.emphasis) {
      case "medium":
        return { opacity: 0.6 }
      case "disabled":
        return { opacity: 0.38 }
      case "high":
      default:
        return { opacity: 1 }
    }
  },
})

const Typography = React.forwardRef(({ classes, children, className, use, align, style }, ref) => (
  <span
    className={classNames(
      className,
      classes[use],
      classes.emphasis
    )}
    style={{
      textAlign: align,
      ...style
    }}
    ref={ref}
    >
    {children}
  </span>
))

Typography.propTypes = {
  className: PropTypes.string,
  use: PropTypes.string,
}

Typography.defaultProps = {
  className: "",
  use: "body1",
  emphasis: "high",
  align: 'left',
}

export default injectStyles(styles)(Typography)
