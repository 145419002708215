// @flow
import * as React from 'react'
import logo from "../assets/images/logo-white.png"
import firebase from 'firebase/app';
import 'firebase/auth';
import * as firebaseui from 'firebaseui'
import * as ciap from 'gcip-iap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'firebaseui/dist/firebaseui.css';
import '../assets/styles/index.css'
import { type Theme } from '../assets/styles/theme'
import withStyles from 'react-jss';
import Typography from "../components/Typography/Typography";
import { Divider } from '../components/Divider';
import * as gcipConfigs from '../utils/gcipConfigs';

require("firebase/firestore");

const styles = (theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.color.backgroundGray.string(),
  },
  jumbotron: {
    padding: [0, 32],
    width: 480,
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.2)'
  },
  logo: {
    backgroundImage: `url(${logo})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    width: 64,
    height: 64,
    margin: '12px auto'
  },
  section: {
    padding: [32, 0],
  }
})

type Props = {
  classes: { [string]: string },
  children?: void,
}

type State = {
  originUrl: string
}

const defaultOriginUrl = "https://cybernetex.ai";

class AuthApp extends React.Component<Props, State> {
  ciapInstance: ciap.Authentication;

  constructor(props) {
    super(props);
    this.state = { originUrl: defaultOriginUrl };
  }

  componentDidMount() {
    if (this.ciapInstance === undefined) {
      fetch('/__/firebase/init.json').then((response) => {
        return response.json();
      }).then((config) => {
        config.authDomain = 'login.cybernetex.ai';
        firebase.initializeApp(config, 'firestore');
      }).then(() => {
        const db = firebase.app('firestore').firestore();
        db.collection("tenants").get().then(function (querySnapshot) {
          var tenantConfigs = []

          querySnapshot.forEach(function (doc) {
            tenantConfigs.push(doc.data());
          });

          return tenantConfigs;
        }).then((tenantConfigs) => {
          return gcipConfigs.generateConfig(tenantConfigs);
        }).then((config) => {
          const handler = new firebaseui.auth.FirebaseUiHandler('#firebaseui-auth-container', config);
          this.ciapInstance = new ciap.Authentication(handler);
          this.ciapInstance.start()

          if (this.state.originUrl == defaultOriginUrl) {
            this.ciapInstance.getOriginalURL().then(
                (url) => {
                  this.setState({ originUrl: url });
                }
            )
                .catch(() => {
                  this.setState({
                    originUrl: defaultOriginUrl,
                  })
                });
          }
        })
      });
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.jumbotron}>
          <div className={classes.section}>
            <div className={classes.logo}></div>
            <Typography
              emphasis='medium'
              align='center'
              use='headline4'
            >
              Cybernetex
            </Typography>
          </div>
          <Divider style={{
            width: 240,
            margin: 'auto',
          }} />
          <div className={classes.section}>
            <Typography
              emphasis='high'
              align='center'
              use='headline6'
            >
              Sign in
            </Typography>
            <Typography
              emphasis='high'
              align='center'
              use='body1'
            >
              to continue to <a href={this.state.originUrl}>{this.state.originUrl.replace(/(^\w+:|^)\/\/|\/+$/g, '')}</a>
            </Typography>
            <br></br>
            <div id="firebaseui-auth-container"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles/*::<Theme>*/(styles)/*::<Props>*/(AuthApp)
