// @flow
import type {Theme} from '../../assets/styles/theme'
import theme from '../../assets/styles/theme'
import Color from 'color'
type ModifierValue = 'onPrimary' | 'onSecondary' | 'onSurface' | 'onError'      // more enums to come

const textEmphasisLookup = {
  disabled: 'disabledEmphasis',
  medium: 'mediumEmphasis',
  high: 'highEmphasis',
  error: 'error'
}

const DEFAULT_EMPHASIS = 'medium'
const DEFAULT_MODIFIER = 'onSurface'

export const textEmphasis = (emphasis: $Keys<typeof textEmphasisLookup> = DEFAULT_EMPHASIS, modifier: ModifierValue = DEFAULT_MODIFIER): typeof Color => {
  if (!textEmphasisLookup[emphasis]) {
    console.warn(`Text emphasis=${emphasis} not implemented`)
  }

  return {
    color: theme.color[textEmphasisLookup[emphasis]][modifier].string()
  }
}

export const mdcTypography = (style: $Keys<$PropertyType<Theme, 'typography'>>) => (theme: Theme) => ({
  ...theme.typography[style],
  marginTop: 0,
  marginBottom: 0,
})

export const mdcTypographyHeight = (lineHeight: string) => ({
  lineHeight: lineHeight,
})

export const lineHeight = (fontSize: number, height: number) => ({
  lineHeight: `${height}rem`,
  '&::before': {
    content: '""',
    display: 'block',
    height: `${height - fontSize}rem`
  }
})

export const mdcTypographyOverflowEllipsis = () => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden'
})

export const mdcTypographyBaselineTop = (distance: number) => ({
  display: 'block',
  marginTop: 0,
  lineHeight: 'normal',
  '&::before': {
    ...mdcTypographyBaselineStrut(distance),
    verticalAlign: 0,
  }
})

export const mdcTypographyBaselineBottom = (distance: number) => ({
  marginBottom: -distance,
  lineHeight: 'normal',
  '&::after': {
    ...mdcTypographyBaselineStrut(distance),
    verticalAlign: -distance,
  }
})

export const mdcTypographyBaselineStrut = (distance: number) => ({
  display: 'inline-block',
  width: 0,
  height: distance,
  content: '""',
})
