import React from 'react'
import injectStyles from 'react-jss'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    borderBottom: '1px solid rgba(0,0,0,.12)'
  },
})

const Divider = ({
  classes,
  className,
  ...props
}) => (
  <div {...props} className={classNames(
    className,
    classes.root,
  )}/>
)
export default injectStyles(styles)(Divider)
