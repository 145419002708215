import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'react-jss'
import { BrowserRouter as Router } from 'react-router-dom'
import AuthApp from './pages/AuthApp'
import * as AppMeta from './AppMeta'

import './assets/styles/index.css'
import { version } from '../package.json'
import defaultTheme from './assets/styles/theme'

const meta = {
  version,
  mode: process.env.NODE_ENV,
}

const root = document.getElementById('root')

if (root) {
  ReactDOM.render((
    <AppMeta.Provider value={meta}>
      <ThemeProvider theme={defaultTheme}>
        <Router>
          <AuthApp />
        </Router>
      </ThemeProvider>
    </AppMeta.Provider>
  ), root)
}
