import firebase from 'firebase/app';

function isSafari(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('safari/') !== -1 &&
        userAgent.indexOf('chrome/') === -1 &&
        userAgent.indexOf('crios/') === -1 &&
        userAgent.indexOf('android/') === -1;
}

function getProviderId(providerType: String): String {
    switch(providerType) {
        case 'email':
            return firebase.auth.EmailAuthProvider.PROVIDER_ID;
        case 'google':
            return firebase.auth.GoogleAuthProvider.PROVIDER_ID
    }
}

type SignInOption = {
    'hd': String,
    'provider': String,
    'disableSignUp': {
        status: boolean,
        helpLink: String,
        adminEmail: String
    },
    'setRequireName': boolean
}

type TenantConfig = {
    'apiKey': String,
    'tenantId': String,
    'signInOptions': Array<SignInOption>,
}

export function generateConfig(tenantConfigs: Iterable<TenantConfig>){
    const config = {}
    const immediateFederatedRedirect = !isSafari();

    tenantConfigs.forEach(element => {
        if (!(element.apiKey in config)) {
            config[element.apiKey] = {
                authDomain: "login.cybernetex.ai",
                displayMode: 'identifierFirst',
                tenants: {}
            }
        }

        config[element.apiKey].tenants[element.tenantId] = {
            signInOptions: element.signInOptions.map(signIn => {
                return {
                    hd: signIn.hd,
                    provider: signIn.provider,
                    requireDisplayName: false,
                    disableSignUp: signIn.disableSignUp
                };
            }),
            immediateFederatedRedirect: immediateFederatedRedirect,
        }
    });
    
    return config;
}